.pagination{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.pageButton{
    cursor: pointer;
    padding: 5px 10px;
    border-width: 0px;
    border-bottom: 1px solid white;
    background-color: white;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 18px;
    transition: 0.1s;
}

.pageButton:hover{
    border-color: black;
}

.ellipsis{
    font-size: 18px;
    display: flex;
    align-items: end;
    padding: 5px 10px;
    cursor: default;
}

@media screen and (max-width: 600px) {
    .pagination{
        gap: 12px;
    }

    .ellipsis{
        padding: 5px 0px;
        margin: 0px 0px;
    }

    .pageButton:hover{
        border-color: white;
    }

    .arrows{
        display: none;
    }
}