.page{
    background-color: white;
    min-height: 100vh;
}

.header-div{
    display: flex;
    position: fixed;
    height: 80px;
    width: 100vw;
    background-color: #daeaf1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    z-index: 100;
}

.title-div{
    color: #54595F;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-bottom: 30px;
    font-size: 22px;
}

#wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.700);
    position: fixed;
    top: 0;
    left: 0;
}

#loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3B66A7; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.svg-settings{
    filter: invert(44%) sepia(16%) saturate(0%) hue-rotate(144deg) brightness(102%) contrast(98%);
}

.svg-settings-white{
    filter: invert(100%) brightness(100%);
}

/* #D0E3EB azul clarinho*/
/* #3B66A7 azul mais forte */
/* #F3F3F3 cinza claro */

/*------------ search items ------------*/
/*------------ search items ------------*/

/* .top-div{
    background-color: #daeaf1;
    padding: 50px 0px;
    margin-bottom: 40px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */

.top-div{
    /* background-color: #daeaf1; */
    margin-bottom: 40px;
    border-radius: 5px;
    display: flex;
    height: 300px;
    flex-direction: row;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.top-div-left{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px -5px 5px 0px, rgba(0, 0, 0, 0.3) 0px 0px 3px -1px; */
    z-index: 25;
}

.top-div-right{
    width: 70%;
    height: 100%;
    background-color: #daeaf1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search-div{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
}

.input-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-input{
    border-width: 0px;
    border-radius: 5px;
    background-color: white;
    padding: 12px 15px;
    width: 22vw;
    height: 22px;
    font-size: 22px;
    outline: none;
    font-family: 'Roboto Regular';
}

.button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
}

.search-button{
    border-width: 0px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 25px;
    background-color: #3B66A7;
    color: white;
    font-family: 'Roboto Regular';
    font-size: 22px;
}

.mag-container{
    display: none;
    background-color: #3B66A7;
    align-items: center;
    justify-content: center;
    width: 30px;
    border-radius: 5px;
}

.mag-svg{
    cursor: pointer;
}

.page-content{
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 80px;
}

#logo-luto{
    width: 250px;
    height: auto;
}

.footer{
    width: 100%;
    height: 50px;
    background-color: #3B66A7;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    font-size: 20px;
    font-family: 'Montserrat';
    color: white;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    margin-top: -50px;
    text-align: center;
}

.p-warn{
    font-family: 'Roboto Regular';
    font-weight: bold;
    font-size: 15px;
}

.top-div-mobile{
    display: none;
    flex-direction: column;
}

.top-div-top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.no-result-div{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    color: #54595F;
    text-align: center;
}


@media screen and (max-width: 1500px) {
    .search-input{
        width: 30vw;
    }
}

@media screen and (max-width: 1200px) {
    .search-input{
        width: 35vw;
    }
    #logo-luto{
        width: 200px;
    }
}

@media screen and (max-width: 1000px) {
    #logo-luto{
        width: 150px;
    }
    .button-container{
        margin-left: 10px;
    }
    .top-div{
        height: 200px;
    }
    .title-div{
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .search-div{
        width: 100%;
    }
    .search-input{
        width: 50vw;
        font-size: 18px;
        padding: 4px 8px;
    }
    /* .top-div{
        display: none;
    }
    .top-div-mobile{
        display: flex;
    } */
    #logo-luto{
        width: 80px;
    }
    .top-div{
        height: 100px;
    }
    .title-div{
        font-size: 12px;
        margin-bottom: 5px;
    }
    .mag-container{
        display: flex;
    }
    .search-button{
        display: none;
    }
    .footer{
        font-size: 14px;
    }
    .no-result-div{
        font-size: 18px;
    }
}