.sv-grid-container{
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1vw;
    margin: 0px 0px;
    /* padding: 0vw 10vw 1.5vw 10vw; */
    padding: 0vw 1vw 1.5vw 1vw;
    box-sizing: border-box;
    font-family: 'Roboto Regular';
}

.sv-title-div{
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 30px;
    font-size: 16px;
    background-color: #3B66A7; 
    color: white; 
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 40px;
}

.sv-item{
    /* background-color: #daeaf1; */
    background-color: #e6edf0;
    padding: 20px;
    border-radius: 2px;
    /* margin-top: 20px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sv-line{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sv-title{
    font-weight: bold;
    margin-right: 5px;
    min-width: 85px;
    font-size: 15px;
}

.sv-info{
    text-align: left;
    font-size: 15px;
}

.sv-top-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    color: #54595F;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.logout-bnt{
    padding: 5px 20px;
    margin-right: 1vw;
    font-size: 18px;
    border: 0px solid black;
    border-radius: 3px;
    cursor: pointer;
    color: #3B66A7;
    background-color: white;
    font-family: 'Roboto Regular';
}

@media screen and (max-width: 1300px) {
    .sv-grid-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 800px) {
    .sv-grid-container {
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

/* #3B66A7 */