@font-face{
  font-family: 'Montserrat';
  src: local('Montserrat'),url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face{
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'),url(./fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face{
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'),url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face{
  font-family: 'Roboto Light';
  src: local('Roboto Light'),url(./fonts/Roboto-Light.ttf) format('truetype');
}

body{
  min-height: 100vh;
  margin: 0;
}

h1{
  margin: 0;
}

p{
  margin: 0;
  padding: 0;
}

#root{
  min-height: 100vh;
}

.prevent-select{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}