.profile-page{
    padding-top: 120px;
    font-family: Arial, Helvetica, sans-serif;
}

.profile-content{
    display: flex;
    justify-content: center;
}

.profile-login-card{
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 200px;
    width: 400px;
    justify-content: center;
}

.profile-register-card{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 80px;
    width: 400px;
    justify-content: center;
}

.profile-login-card-top{
    color: #3B66A7;
    font-size: 20px;
    text-align: center;
}

.profile-login-card-fields{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
}

.profile-login-inputs{
    border: 0;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 7px 1px;
    height: 50px;
    font-size: 22px;
    padding-left: 15px;
    outline: none;
    width: 383px;
}

.profile-login-inputs-title{
    font-size: 22px;
    margin: 0 0 3px 2px;
    color: #54595f;
}

.profile-login-card-buttons{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.profile-login-buttons{
    border: 0;
    border-radius: 7px;
    background-color: white;
    color: rgb(97, 97, 97);
    width: 190px;
    height: 65px;
    font-size: 24px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 1px;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
}

.profile-login-buttons:hover{
    transform: scale(1.05);
}