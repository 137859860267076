.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 0px 0px;
    padding: 20px 15vw 40px 15vw;
    box-sizing: border-box;
}

.grid-item {
    background-color: #daeaf1;
    padding: 30px 20px 30px 20px;
    border-radius: 8px;
    /* margin-top: 20px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
}

.item-column{
    display: flex;
    flex-direction: column;
    width: 33%;
    justify-content: space-evenly;
}

.item-title{
    margin-bottom: 10px;
}

.item-info{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100px
}

.info-div{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title{
    display: inline;
    font-weight: 650;
    margin-right: 5px;
    color: #54595F;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.info{
    display: inline;
    color: #54595F;
    font-family: 'Roboto Regular', sans-serif;
    font-size: 16px;
    padding: 1px 0px 0px 8px;
}

@media screen and (max-width: 1500px) {
    .grid-container {
        padding: 40px 8vw;
    }
}

@media screen and (max-width: 1300px) {
    .grid-container {
        padding: 40px 5vw;
    }
}

@media screen and (max-width: 1000px) {
    .grid-container {
        padding: 40px 5vw;
    }
}

@media screen and (max-width: 800px) {
    .grid-container {
        padding: 40px 5vw;
        gap: 25px;
    }

    .grid-item{
        flex-direction: column;
    }

    .item-column{
        width: 100%;
        margin-bottom: 20px;
    }
}