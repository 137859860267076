.search-contract-div{
    width: 100%;
    font-size: 22px;
    font-family: 'Roboto Regular';
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.form{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.category-input{
    width: 200px;
    font-size: 20px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
}